<template>
  <v-card
    rounded
    outlined
    class="p-3 rounded bg-transparent text-left agentCard"
    :class="{ selectedAgent: selected, border: !selected }"
    @click="getAgent(agents)"
  >
    <div class="text-black box-wrapper" v-if="agents">
      <div class="text-center agent-header">
        <img
          :src="
            agents.Photo
              ? `${$store.getters.hostURL}${agents.Photo}`
              : 'https://ritecaremedicalofficepc.com/wp-content/uploads/2019/09/img_avatar.png'
          "
          class="agentImage"
          :alt="agents.Name"
        />
      </div>
      <div class="d-flex flex-column justify-content-between p-0 agent-body">
        <v-card-title class="username pt-0 pb-0">
          <div>{{ agents.Name }} {{ agents.LastName }}</div>
        </v-card-title>
        <v-card-subtitle class="subtitle">
          {{ agents.City }} {{ agents.Country }}
        </v-card-subtitle>
        <v-card-text class="contact-info pb-0 mt-0">
          <p></p>
          <p>
            <img src="@/assets/img/icons/phone.svg" alt="phone" />
            {{ agents.Mobile }}
          </p>
          <p v-if="agents.Whatsapp">
            <img src="@/assets/img/icons/whatsapp.svg" alt="whatsapp" />
            {{ agents.Whatsapp }}
          </p>
<!--
          <p v-if="agents.Email">
            <img src="@/assets/img/icons/email.svg" alt="email" />
            {{ agents.Email }}
          </p>
-->
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["agents", "selected"],
  data() {
    return {
      agentProps: {
        width: 150,
        height: 150,
        class: "m-0",
        style: "border-radius:5px ",
      },
    };
  },
  methods: {
    getAgent(value) {
      this.$store.dispatch("reservationAgent", value);
    },
  },
};
</script>

<style lang="scss">
.selectedAgent {
  border: 1px solid #5205db !important;
  border-right: 11px solid hsl(262, 96%, 44%) !important;
  @media (max-width: 767px) {
    border-bottom: 8px solid #5205db !important;
    border-right-width: 1px !important;
  }
  .subtitle {
    color: #5205db !important;
  }
  .box-wrapper {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  .agent-header {
    margin-right: 0;
    width: 100%;
    min-width: 100%;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/custom.scss";

.agentImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px !important;
}
@media (max-width: 768px) {
  .agentImage {
    width: 100%;
  }
}
.subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #006ac3;
  padding-bottom: 30px;
  margin-top: 0 !important;
  padding-right: 0;
  padding-left: 0;
  @media (max-width: 767px) {
    padding-bottom: 24px;
    padding-left: 0;
    font-size: 14px;
    font-weight: 400;
  }
}
.contact-info {
  font-size: 16px;
  padding-right: 0;
  padding-left: 0;
  @media (max-width: 767px) {
    padding-left: 0;
  }
  img {
    margin-top: 2px;
  }
  p {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 5px;
    @media (max-width: 575px) {
      font-size: 14px;
    }
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
}
.box-wrapper {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.agent-header {
  margin-right: 24px;
  width: 220px;
  min-width: 220px;
  height: 208px;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.agent-body {
  width: 100%;
}
.username {
  font-weight: 600;
  padding-right: 0;
  padding-left: 0;
  @media (max-width: 767px) {
    padding-left: 0;
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
